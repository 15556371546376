import React, { useContext, useEffect, useState } from 'react';
import logo from '../assets/personal-report-logo.png';
import { Checkbox } from 'flowbite-react';
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import './signup.css';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import LoginButton from '../Components/LoginButton';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import ReCAPTCHA from 'react-google-recaptcha';

const Signup = () => {
    const [fullName, setFullName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const { registerUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const phoneUtil = PhoneNumberUtil.getInstance();
    const { user } = useContext(AuthContext);
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const recaptchaSiteKey = "6LedRFwqAAAAAHnwmTlh3NOP4oyUYMeEmTiVtdoW"; // Replace with your reCAPTCHA site key

    const isPhoneValid = (phone) => {
        if (phone.length < 11) {
            return false;
        } else if (phone.length > 14) {
            return false;
        } else {
            return true;
        }
    };

    const isValid = isPhoneValid(phone);

    const [touched, setTouched] = useState({
        fullName: false,
        email: false,
        password: false,
        confirmPassword: false,
        phone: false,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const gotoLoginPage = () => {
        let path = `/`;
        navigate(path);
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        if (!recaptchaToken) {
            Swal.fire({
                title: 'Warning!',
                text: 'Please complete the reCAPTCHA.',
                icon: 'warning',
            });
            return;
        }

        if (!fullName || !email || !password || !confirmPassword || !recaptchaToken) {
            Swal.fire({
                title: 'Warning!',
                text: 'Please enter all required fields.',
                icon: 'warning',
            });
            return;
        }

        if (!isValidEmail(email)) {
            Swal.fire({
                title: 'Warning!',
                text: 'Please enter a valid email address.',
                icon: 'warning',
            });
            return;
        }

        if (!isValid) {
            Swal.fire({
                title: 'Warning!',
                text: 'Please enter a valid phone number.',
                icon: 'warning',
            });
            return;
        }

        if (password.length < 8) {
            Swal.fire({
                title: 'Warning!',
                text: 'Password must be at least 8 characters long.',
                icon: 'warning',
            });
            return;
        }

        if (password !== confirmPassword) {
            Swal.fire({
                title: 'Warning!',
                text: 'Passwords do not match.',
                icon: 'warning',
            });
            return;
        }

        setLoading(true);

        try {
            const data = await registerUser(fullName, phone, email, password, confirmPassword);
            setLoading(false);
            if (data) {
                Swal.fire({
                    title: 'Registration Successful!',
                    text: "Please activate your account by checking your email and clicking the activation link we sent you.",
                    icon: 'success',
                    confirmButtonText: 'Ok',
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/');
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                title: 'Warning!',
                text: 'Registration Failed. Something went wrong.',
                icon: 'warning',
            });
        }
    };

    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setType('text');
        } else {
            setIcon(eyeOff);
            setType('password');
        }
    };

    const handleBlur = (field) => {
        setTouched({ ...touched, [field]: true });
    };

    return (
        <div>
            {user && <Navbar />}
            <div className='signup-not-user-top-part'>
                <img src={logo} alt='signup-logo' />
                <div className='signup-not-user-top-part-title'>Daily Report</div>
            </div>

            <div className='signup-form'>
                <div className='signup-form-left-part'>
                    <div className='signup-form-left-part-signup-title'>Sign Up</div>
                    <form onSubmit={handleRegister}>
                        <div>
                        <input
                            placeholder='Full Name'
                            type='text'
                            name='first_name'
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            onBlur={() => handleBlur('fullName')}
                            required
                            className='signup-form-left-part-input'
                        />
                        {touched.fullName && !fullName && (
                            <div style={{ color: 'red' }}>Please enter your full name.</div>
                        )}
                        </div>

                        
                        <div>
                        <input
                            name='username'
                             type='text'
                            value={phone}
                            placeholder='Phone'
                            className='signup-form-left-part-input'
                            onChange={(e) => setPhone(e.target.value)}
                            required
                            onBlur={() => handleBlur('phone')}
                        />
                        {touched.phone && !isValid && (
                            <div style={{ color: 'red' }}>Phone is not valid</div>
                        )}
                        </div>


                        <div>
                            <input
                                placeholder='Email'
                                type='email'
                                name='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
                                required
                                onBlur={() => handleBlur('email')}
                                className='signup-form-left-part-input'
                            />
                            {touched.email && !isValidEmail(email) && (
                                <div style={{ color: 'red' }}>Please enter a valid email.</div>
                            )}
                        </div>
                        

                        <div className='signup-form-left-part-password'>
                            <div className='signup-show-password'>
                                <input
                                    placeholder='Password'
                                    type={type}
                                    name='Password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    onBlur={() => handleBlur('password')}
                                    className='signup-form-left-part-input'
                                    autoComplete='current-password'
                                />
                                <span
                                    className='flex justify-around items-center'
                                    onClick={handleToggle}
                                >
                                    <Icon className='absolute mr-10' icon={icon} size={15} />
                                </span>
                            </div>
                            {touched.password && password.length < 8 && (
                                <div className='password-hints'>
                                    Password must be 8 characters long.
                                </div>
                            )}
                        </div>

                        <div className='signup-form-left-part-password'>
                            <div className='signup-show-password'>
                                <input
                                    placeholder='Confirm Password'
                                    type={type}
                                    name='Password'
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                    className='signup-form-left-part-input'
                                    onBlur={() => handleBlur('confirmPassword')}
                                    autoComplete='current-password'
                                />
                                <span
                                    className='flex justify-around items-center'
                                    onClick={handleToggle}
                                >
                                    <Icon className='absolute mr-10' icon={icon} size={15} />
                                </span>
                            </div>
                            {touched.confirmPassword && password !== confirmPassword && (
                            <div className='password-hints'>Passwords don't match</div>
                            )}
                            
                        </div>

                        
                        <div className='recaptcha-container'>
                            <ReCAPTCHA
                            className='g-recaptcha'
                                sitekey={recaptchaSiteKey}
                                onChange={(token) => setRecaptchaToken(token)}
                                />
                        </div>

                        <Button
                            className='signup-form-left-part-button'
                            type='submit'
                        //disabled={!recaptchaToken}
                        >
                            {loading ? 'Loading...' : 'SIGN UP'}
                        </Button>

                        <div style={{ alignSelf: 'center' }}>OR</div>

                        <LoginButton />
                    </form>

                    <div className='signup-lower-part'>
                        <div>Already have an account?</div>
                        <span onClick={gotoLoginPage}>Login</span>
                    </div>
                </div>

                <div className='signup-form-right-part'>
                    <img src={logo} alt='signup-logo' />
                </div>
            </div>

            <MyFooter />
        </div>
    );
};

export default Signup;