import React, { useContext, useEffect, useRef, useState } from 'react';
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import AuthContext from '../context/AuthContext';
import './account.css';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import config from '../config';

const Account = () => {
    const API_URL = config.apiUrl;
    const { logoutUser, user, authTokens } = useContext(AuthContext);
    const token = authTokens?.access;
    // console.log(`Access token`, token);
    const [profileData, setProfileData] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (!user) {
            alert('You are not logged in. Please log in to access your profile.');
            navigate('/');
        } else {
            // Fetch user data and set it to profileData
            fetch(`${API_URL}userInfo/${user.user_id}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                },)
                .then(response => response.json())
                .then(data => {
                    setProfileData(data);
                })
                .catch(error => console.error('Error fetching user data:', error));
        }
    }, [user]);

    //------------------Profile-----------------


    const status = {
        "Worker": "Worker",
        "Associate": "Associate",
        "Active Associate": "Active Associate",
        "Member": "Member",
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            is_active:true,
            [name]: value,
        });
    };

    const handleSelectChange = (e) => {
        setProfileData({
            ...profileData,
            status: e.target.value,
            
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Update user data on the backend
        fetch(`${API_URL}userInfo/${user.user_id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(profileData),
        })
            .then(response => {
                if (response.ok) {
                    //alert('Profile updated successfully!');
                    Swal.fire({
                        title: "Al'hamdulillah",
                        text: "Profile updated successfully!",
                        icon: "success",
                    });
                } else {
                    throw new Error('Failed to update profile');
                }
            })
            .catch(error => console.error('Error updating profile:', error));
    };

    return (
        <div className='account-container'>
            {user &&
                <div>
                    <Navbar />
                    <div className='profile-holder'>
                        <div className='profile-main'>
                            <form className='profile-info' >
                                <div className='profile-info-2'>
                                    <label>{t("name")}
                                        <input
                                            name="firstName"
                                            placeholder='First Name'
                                            value={profileData?.firstName || ''}
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                </div>

                                <div className='profile-info-2'>
                                    <label>

                                        <div> {t("branch")} <span style={{ color: 'red', cursor: 'pointer' }} title="This field is important.">*</span> </div>
                                        <input
                                            name="branch"
                                            placeholder='Branch'
                                            value={profileData?.branch || ''}
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                </div>

                                <div className='profile-info-2'>
                                    <label>
                                        {t("status")}
                                        <select
                                            name="status"
                                            value={profileData?.status || ""}
                                            onChange={handleSelectChange}
                                        >
                                            {Object.keys(status).map((key) => (
                                                <option
                                                    key={key}
                                                    value={key}
                                                    placeholder='Select your Status'
                                                >
                                                    {status[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                </div>

                                <div className='profile-info-2'>
                                    <label>
                                        {t("mobile")}
                                        <input
                                            name="username"
                                            placeholder="Mobile No."
                                            onChange={handleInputChange}
                                            value={profileData?.username || ''}
                                        />
                                    </label>
                                </div>
                                    <Link to={`/settings`}>
                                <div className='profile-info-2'>
                                    <label>
                                        {t("email")}
                                            <label
                                                className='email_field'
                                            >
                                                
                                            {profileData?.email || "No email available"}
                                                </label>
                                                
                                    </label>
                                </div>
                                            </Link>
                                <Button type='submit' onClick={handleSubmit}>Update</Button>
                            </form>
                        </div>
                    </div>
                </div>
            }
            <MyFooter />
        </div>
    );
};

export default Account;

