import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import './syllabus.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ShareIcon from '@mui/icons-material/Share';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { API } from '../api-service';
import Swal from 'sweetalert2';
import config from '../config';
import kalpurush from './Kalpurush';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';

const Syllabus = () => {
  // console.log("the kalpurush",kalpurush)
  const { user, authTokens } = useContext(AuthContext);
  const token = authTokens?.access;
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [allBooks, setAllBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);

  const [userinfo, setuserinfo] = useState({});
  const [bookRead, setbookRead] = useState([]);
  const navigate = useNavigate();
  //const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (!user) {
      Swal.fire({
        title: 'Warning',
        text: "You are not logged in. Please log in to access your profile.",
        icon: 'warning',
        confirmButtonText: 'Ok.'
      }).then((result) => {
        if (result['isConfirmed']) {
          navigate('/'); 
        }
      });
    }
  }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function fetchFilterOptions() {
      try {
        const response = await fetch(`${config.apiUrl}filter-options`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setFilterOptions(data);
      } catch (error) {
        console.error("Failed to fetch filter options:", error);
      }
    }

    fetchFilterOptions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    API.getBooks(token)
      .then(resp => setAllBooks(resp || []))
      .catch(error => console.log(error));
  }, [token]);
  // console.log("all books ", allBooks);
  // Use another useEffect to log the updated state

  // Function to handle when filters are selected
  const handleCheckboxChange = (filterId) => {
    setSelectedFilters(prevSelected =>
      prevSelected.includes(filterId)
        ? prevSelected.filter(id => id !== filterId) // Uncheck: Remove filterId
        : [...prevSelected, filterId] // Check: Add filterId
    );
  };

  // Filter books based on selected filter options
  useEffect(() => {
    const filtered = allBooks.filter(book =>
      selectedFilters.some(filterId => book.filterOptions.includes(Number(filterId)))
    );
    setFilteredBooks(filtered);
    // console.log("filtered books",filteredBooks)
  }, [selectedFilters, allBooks]);


  useEffect(() => {
    if (user) {
      API.getUserbookData(user.user_id, token)
        .then(resp => setuserinfo(resp || {}))
        .catch(error => console.log(error));
    }
  }, [user, token]);

  useEffect(() => {
    setbookRead(userinfo.bookRead || []);
  }, [userinfo]);

  const toggleHandler = (book) => {
    const arr = [...bookRead];
    const newId = book.id;

    if (!arr.includes(newId)) {
      arr.push(newId);
    } else {
      arr.splice(arr.indexOf(newId), 1);
    }

    setbookRead(arr);
  };

  const updateBookData = async () => {
    const data = { bookRead };
    try {
      await axios.patch(`${config.apiUrl}userInfo/${user.user_id}/`, data,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      Swal.fire({
        title: "Saved!",
        text: "Book reading successfully updated.",
        icon: "success"
      });
    } catch {
      Swal.fire({
        title: "Save Failed!",
        text: "Book reading not updated!",
        icon: "warning"
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const generatePdf = () => {
    const doc = new jsPDF();
    const myFont =kalpurush;
    doc.addFileToVFS('kalpurushANSI.ttf',myFont);
    // add the font to jsPDF
// doc.addFileToVFS("MyFont.ttf", myFont);
doc.addFont("kalpurushANSI.ttf", "kalpurush", "normal");
doc.setFont("kalpurush");
// console.log(doc.setFont("kalpurush"));
    const tableColumn = ['SL', 'Book Name', 'Writer Name', 'Read'];
    const tableRows = [];
  
    (selectedFilters.length === 0 ? allBooks : filteredBooks).forEach((book, index) => {
      const bookData = [
        index + 1,
        book.bookName,
        book.bookWriter,
        bookRead.includes(book.id) ? 'Yes' : 'No',
      ];
      tableRows.push(bookData);
    });
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      margin: { top: 10, left: 10, right: 10, bottom: 10 },
      theme: 'grid',
      headStyles: { fillColor: [255, 0, 0] }, // Optional: custom styles for the table header
      didParseCell: function (data) {
        // Change text color for 'Yes' and 'No' in the 'Read' column
        if (data.column.index === 3) {
          if (data.cell.raw === 'Yes') {
            data.cell.styles.textColor = [0, 255, 0]; // Green text for 'Yes'
          } else if (data.cell.raw === 'No') {
            data.cell.styles.textColor = [255, 0, 0]; // Red text for 'No'
          }
        }
      },
    });
  
    doc.save('syllabus-data.pdf');
  };
  

  // const downloadPDF = () => {
  //   const doc = generatePdf();
  //   doc.save('syllabus_data.pdf'); // Download the PDF with the given filename
  // };

  const sharePdf = () => {
    const doc = new jsPDF();

    // Generate the table content
    const data = (selectedFilters.length === 0 ? allBooks : filteredBooks).map((book, id) => [
      id + 1,
      book.bookName,
      book.bookWriter,
      bookRead.includes(book.id) ? 'Yes' : 'No',
    ]);

    // Add the table to the PDF
    autoTable(doc, {
      head: [['#', 'Book Name', 'Writer Name', 'Read?']],
      body: data,
      margin: { top: 10 },
    });

    // Output the PDF as a blob
    const pdfBlob = doc.output('blob');
    const file = new File([pdfBlob], 'syllabus-data.pdf', {
      type: 'application/pdf',
    });

    // Check if the navigator.share API is supported
    if (navigator.share && navigator.canShare({ files: [file] })) {
      navigator
        .share({
          title: 'Syllabus Data',
          files: [file],
        })
        .catch((error) => console.error('Error sharing', error));
    } else {
      alert('Your browser does not support the share feature.');
    }
  };

  return (
    <div>
      <Navbar />
      <Container className='syllabus-container'>
        <Row className='my-4'>
          <Col md={12}>
            <h2>{t('syllabus')}</h2>
          </Col>
        </Row>

        <Row className='my-4'>
        <Col md={6}>
  <div className='category-filter'>
    <div className="filter-grid">
      {filterOptions.map(option => (
        <div key={option.id} className="filter-item">
          <input
            type="checkbox"
            className='p-2 m-2'
            value={option.id}
            checked={selectedFilters.includes(option.id)}
            onChange={() => handleCheckboxChange(option.id)}
          />
          <label>
            {option.name}
          </label>
        </div>
      ))}
    </div>
  </div>
</Col>


          <Col md={6}>
            <div className='summary'>
              <div className='summary-item'>
                <span className='label'>{t('t-books')}</span>
                <span className='value '> <b> {allBooks?.length || 0} </b></span>
              </div>
              <div className='summary-item'>
                <span className='label'>{t('read')}</span>
                <span className='value'>  <b>{bookRead?.length || 0}</b></span>
              </div>
            </div>
          </Col>
        </Row>

        <div className='book-list-container'>
          <div className='book-list-header'>
            {/* <div className='book-list-row'> */}
            <div className='header-item'>{t('sl')}</div>
            <div className='header-item'>{t('b-name')}</div>
            <div className='header-item'>{t('w-name')}</div>
            <div className='header-item'>{t('read?')}</div>
          </div>

          <div className='book-list-body'>
            {(selectedFilters.length === 0 ? allBooks : filteredBooks).map((book, id) => (
              <div className='book-list-row' key={book.id}>
                <div className='row-item'>{id + 1}</div>
                <a href={`${book.bookLink}`} target="_blank" rel="noopener noreferrer">
                  <div className='row-item'>
                    {
                      book.bookName}
                  </div>
                </a>
                <div className='row-item'>{book.bookWriter}</div>
                <div className='row-item'>
                  <input
                    type="checkbox"
                    onChange={() => toggleHandler(book)}
                    checked={bookRead.includes(book.id)}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className='book-list-footer'>
            <Button size='sm' onClick={updateBookData}>{t('save')}</Button>
            <div className='actions'>
              <Button size='sm' onClick={generatePdf}>
                <DownloadForOfflineIcon /> {t('download')}
              </Button>
              <Button size='sm' onClick={sharePdf}>
                <ShareIcon /> {t('share')}
              </Button>
            </div>
          </div>
        </div>
      </Container>
      <MyFooter />
    </div>
  );
}

export default Syllabus;
